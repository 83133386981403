<script>
import ChannelsConnectModal from '@/components/CommunicationChannels/channelsConnectModal.vue';
import ChannelsWhatsappLoginButton from '@/components/Skills/Channels/LoginButtons/ChannelsWhatsappLoginButton.vue';
import ChannelsFacebookLoginButton from './Channels/LoginButtons/ChannelsFacebookLoginButton.vue';
import ChannelsInstagramLoginButton from './Channels/LoginButtons/ChannelsInstagramLoginButton.vue';
import ChannelsWebChatLoginButton from './Channels/LoginButtons/ChannelsWebChatLoginButton.vue';
import MainAppsShopifyConnectButton from '@/components/Skills/MainApps/MainAppsShopifyConnectButton.vue';
import MainAppsPrestashopConnectButton from '@/components/Skills/MainApps/MainAppsPrestashopConnectButton.vue';
import MainAppsConfigValuesForm from '@/components/Skills/MainApps/MainAppsConfigValuesForm.vue';
import { mapActions } from 'vuex';

export default {
    name: 'SkillDetailsInfoCard',
    components: {
        ChannelsWhatsappLoginButton,
        ChannelsFacebookLoginButton,
        ChannelsInstagramLoginButton,
        ChannelsWebChatLoginButton,
        MainAppsShopifyConnectButton,
        MainAppsPrestashopConnectButton,
        MainAppsConfigValuesForm,
    },
    props: {
        skill: {
            type: Object,
            required: true,
        },
        isEnable: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['skill-enabled', 'skill-disabled'],
    computed: {
        defaultAvatarUrl() {
            return process.env.VUE_APP_USER_AVATAR_URL;
        },
        isWhatsappSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cc';
        },
        isInstagramSKill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cd';
        },
        isFacebookMessengerSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284ce';
        },

        isShopifySkill() {
            return this.skill.uuid === 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
        },
        isPrestashopSkill() {
            return this.skill.uuid === '124f8439-bca9-420a-8f20-c1b80e5f7512';
        },
        isWebChatSkill() {
            return this.skill.uuid === '36578113-0f50-4a38-ac06-3130f188281e';
        },
        hasConfigSchema() {
            return this.skill.config_schema && Object.keys(this.skill.config_schema).length > 0;
        },
    },
    methods: {
        ...mapActions('modalStore', ['openModal']),
        enableSkill() {
            this.$emit('skill-enabled');
        },

        disableSkill() {
            this.$emit('skill-disabled');
        },

        openConfigSchemaForm() {
            this.openModal({
                component: MainAppsConfigValuesForm,
                props: {
                    configSchemaValues: { ...this.skill.config_schema },
                },
                onEvent: (eventName, eventPayload) => {
                    if (eventName === 'form-submitted') {
                        this.$emit('skill-enabled', eventPayload);
                    }
                },
            });
        },
    },
};
</script>

<template>
    <md-card>
        <md-card-content>
            <div class="skills-details-info-card">
                <div class="skills-details-info-card__skill-info">
                    <div class="skills-details-info-card__skill-logo">
                        <img v-if="skill.image && skill.image !== ''" :src="skill.image" alt="Skill Image" />
                        <img v-else :src="defaultAvatarUrl" alt="Lixsa default image" />
                    </div>
                    <div class="skills-details-info-card__skill-description">
                        <div class="md-display-1">
                            <strong> {{ skill.name }} </strong>
                        </div>
                        <div class="md-body-1">{{ skill.description }}</div>
                        <div class="skills-details-info-card__skill-categories">
                            <!--              <md-chip class="skills-details-info-card__Active"></md-chip>-->
                            <md-chip v-for="category in skill.categories">{{ category }}</md-chip>
                        </div>
                    </div>
                </div>
                <div>
                    <channels-whatsapp-login-button
                        @account-connected="enableSkill"
                        v-if="isWhatsappSkill && !isEnable"
                    ></channels-whatsapp-login-button>

                    <channels-instagram-login-button
                        @account-connected="enableSkill"
                        v-else-if="isInstagramSKill && !isEnable"
                    ></channels-instagram-login-button>

                    <channels-facebook-login-button
                        @account-connected="enableSkill"
                        v-else-if="isFacebookMessengerSkill && !isEnable"
                    ></channels-facebook-login-button>

                    <channels-web-chat-login-button
                        @account-connected="enableSkill"
                        v-else-if="isWebChatSkill && !isEnable"
                    ></channels-web-chat-login-button>

                    <main-apps-shopify-connect-button
                        @account-connected="enableSkill"
                        v-else-if="isShopifySkill && !isEnable"
                    ></main-apps-shopify-connect-button>

                    <main-apps-prestashop-connect-button
                        @account-connected="enableSkill"
                        v-else-if="isPrestashopSkill && !isEnable"
                    ></main-apps-prestashop-connect-button>

                    <md-button
                        @click="openConfigSchemaForm"
                        v-else-if="hasConfigSchema && !isEnable"
                        class="md-primary"
                    >
                        Configure skill
                    </md-button>

                    <md-button @click="enableSkill" v-else-if="!isEnable" class="md-primary">Enable Skill</md-button>

                    <md-button @click="disableSkill" class="md-gray md-outline" v-else>Disable Skill</md-button>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.skills-details-info-card {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 17px;

    @media (max-width: 959px) {
        flex-direction: column;
        gap: 1.5rem;
    }

    &__skill-info {
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 1.5rem;

        @media (max-width: 959px) {
            flex-direction: column;
        }
    }

    &__skill-logo {
        flex: 0 0 80px;
        border-radius: 12px;
        overflow: hidden;

        img {
            width: 80px;
            height: auto;
        }
    }

    &__skill-description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__skill-categories {
        display: flex;
        align-items: start;
        justify-content: start;

        @media (max-width: 959px) {
            display: none;
        }
    }
}
</style>
