<template>
    <div>
        <md-button @click="openModal" class="md-primary" :disabled="isLoading">
            <md-progress-spinner
                v-if="isLoading"
                :md-diameter="12"
                :md-stroke="2"
                class="md-white"
                md-mode="indeterminate"
            ></md-progress-spinner>
            <span v-else>Connect</span>
        </md-button>

        <!-- Modal for allowed domains -->
        <md-dialog :md-active.sync="showModal">
            <md-dialog-title>Configure WebChat</md-dialog-title>

            <md-dialog-content>
                <div class="modal-content">
                    <p>Enter domains that are allowed to integrate with your WebChat</p>

                    <div class="domain-list">
                        <div v-for="(domain, index) in allowedDomains" :key="index" class="domain-item">
                            <md-field>
                                <md-input v-model="allowedDomains[index]" placeholder="example.com"></md-input>
                            </md-field>
                            <md-button class="md-icon-button md-dense" @click="removeDomain(index)">
                                <md-icon>close</md-icon>
                            </md-button>
                        </div>
                    </div>

                    <md-button class="md-primary md-raised add-domain-btn" @click="addDomain">
                        <md-icon>add</md-icon>
                        Add Domain
                    </md-button>

                    <div class="domain-info">
                        <md-icon>info</md-icon>
                        <span>
                            Enter domains without protocol (http://, https://). Use * as wildcard (e.g., *.example.com).
                        </span>
                    </div>
                </div>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-accent" @click="showModal = false">Cancel</md-button>
                <md-button class="md-primary" @click="createWebChatChannel" :disabled="isLoading || !hasValidDomains">
                    <md-progress-spinner
                        v-if="isLoading"
                        :md-diameter="16"
                        :md-stroke="2"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <span v-else>Create Channel</span>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import webChatService from '@/services/webchat/webChatService';
import { mapGetters } from 'vuex';

export default {
    name: 'ChannelsWebChatLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            isLoading: false,
            showModal: false,
            allowedDomains: [''],
        };
    },

    computed: {
        ...mapGetters({
            account: 'user/account',
        }),

        hasValidDomains() {
            return this.allowedDomains.some((domain) => domain.trim() !== '');
        },
    },

    created() {
        // Initialize with shop_public_domain if available
        if (this.account && this.account.shop_public_domain) {
            this.allowedDomains = [this.getDomainFromUrl(this.account.shop_public_domain)];
        }
    },

    methods: {
        getDomainFromUrl(url) {
            // Clean the URL to extract just the domain part
            try {
                // Add protocol if missing to make URL parsing work
                if (!url.match(/^https?:\/\//i)) {
                    url = 'https://' + url;
                }

                const urlObj = new URL(url);
                return urlObj.hostname;
            } catch (e) {
                // If URL parsing fails, try a simple regex to extract domain
                const domainMatch = url.match(/(?:https?:\/\/)?(?:www\.)?([^\/\s]+)/i);
                return domainMatch ? domainMatch[1] : url;
            }
        },

        openModal() {
            this.showModal = true;

            // Refresh allowed domains with shop_public_domain when opening modal
            if (this.allowedDomains.length === 1 && this.allowedDomains[0] === '' && this.account.shop_public_domain) {
                this.allowedDomains = [this.getDomainFromUrl(this.account.shop_public_domain)];
            }
        },

        addDomain() {
            this.allowedDomains.push('');
        },

        removeDomain(index) {
            if (this.allowedDomains.length > 1) {
                this.allowedDomains.splice(index, 1);
            } else {
                this.allowedDomains = [''];
            }
        },

        validateDomains() {
            // Filter out empty domains
            return this.allowedDomains.filter((domain) => domain.trim() !== '');
        },

        async createWebChatChannel() {
            this.isLoading = true;

            try {
                const validDomains = this.validateDomains();

                if (validDomains.length === 0) {
                    this.showErrorToast('Please enter at least one domain');
                    this.isLoading = false;
                    return;
                }

                await webChatService.setupWebChat(validDomains);

                this.showSuccessToast('WebChat channel created successfully');
                this.showModal = false;
                this.$emit('account-connected');
            } catch (error) {
                console.error('Error creating WebChat channel:', error);
                this.showErrorToast('Error creating WebChat channel');
            } finally {
                this.isLoading = false;
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style scoped>
.modal-content {
    padding: 0 16px;
}

.domain-list {
    margin-bottom: 16px;
}

.domain-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.domain-item .md-field {
    flex: 1;
    margin-right: 8px;
}

.add-domain-btn {
    margin-bottom: 16px;
}

.domain-info {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 16px;
}

.domain-info .md-icon {
    font-size: 16px;
    min-width: 16px;
    margin-right: 8px;
}
</style>
