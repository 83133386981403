<script>
import CodeSnippet from '@/components/codeSnippet.vue';
export default {
    name: 'MainAppsMagentoAboutCard',
    components: { CodeSnippet },
};
</script>

<template>
    <md-card>
        <md-card-content class="skill-about-card">
            <div class="md-title">
                <strong> What can the Magento Skill do for your business? </strong>
            </div>

            <div class="md-body-1">
                The Magento skill allows you to connect your Magento store to Lixsa. This will enable you to automate
                your customer service and sales processes, and provide a seamless experience for your customers.
            </div>

            <div class="md-subheading">
                <strong> Lixsa knows about your catalog </strong>
            </div>
            <div class="md-body-1">
                The catalog Skill is a crucial enhancement for Lixsa, you can access it and interact with comprehensive
                customer orders information. This capability is designed to facilitate more personalized and efficient
                customer service interactions by allowing Lixsa to retrieve order histories, track current order
                statuses, and provide updates directly to customers inquiring about their purchases.
            </div>

            <div class="md-subheading">
                <strong> Lixsa is ready to answer about your store configurations </strong>
            </div>
            <div class="md-body-1">
                The store configurations will be available for Lixsa to answer any questions your customers may have
                about your Magento store. This includes information about shipping, returns, and other policies you may
                have in place.
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.skill-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
