<script>
import { mapGetters } from 'vuex';
import AboutCard from './MainAppsMagentoAboutCard.vue';
import ConnectWhatsAppCta from './DetailCards/MainAppsShopifyConnectWhatsAppCta.vue';
import SandboxCta from './DetailCards/MainAppsShopifySandboxCta.vue';
import RecommendedSkills from './DetailCards/MainAppsShopifyRecommendedSkills.vue';
import SyncStatus from './DetailCards/MainAppsShopifySyncStatus.vue';

export default {
    name: 'MainAppsMagentoConnectionDetails',
    components: {
        AboutCard,
        ConnectWhatsAppCta,
        SandboxCta,
        RecommendedSkills,
        SyncStatus,
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),
        showWhatsAppCta() {
            return !this.getSkillByInternalName('whatsapp');
        },
    },
};
</script>

<template>
    <div class="magento-connection-details">
        <div class="magento-connection-details__content-wrapper">
            <div class="magento-connection-details__left-column">
                <About-card class="magento-connection-details__about-card" />
                <sandbox-cta class="magento-connection-details__cta-card" />
                <connect-whats-app-cta v-if="showWhatsAppCta" class="magento-connection-details__cta-card" />
            </div>
            <div class="magento-connection-details__right-column">
                <sync-status class="magento-connection-details__sync-status" />
            </div>
        </div>
        <div class="md-title">Recommended Skills for this App</div>
        <recommended-skills class="magento-connection-details__recommended-skills" />
    </div>
</template>

<style lang="scss" scoped>
.md-card {
    margin: 0;
}

.magento-connection-details {
    margin: 0 auto;
    padding: 0;

    &__content-wrapper {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1rem;
        margin-bottom: 2rem;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    &__left-column,
    &__right-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
    }

    &__info-and-cta {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__recommended-skills {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}
</style>
