<script>
import { mapActions, mapGetters } from 'vuex';
import SkillDetailsInfoCard from '@/components/Skills/SkillDetailsInfoCard.vue';
import SkillsService from '@/services/skills/SkillsService';
import AgentSettingsService from '@/services/account/agentSettingsService';
import SkillDetailsAboutCard from '@/components/Skills/SkillDetailsAboutCard.vue';
import Swal from 'sweetalert2';
import ChannelsWhatsappAboutCard from '@/components/Skills/Channels/ChannelsWhatsappAboutCard.vue';
import ChannelsWhatsappHowToSetupCard from './Channels/ChannelsWhatsappHowToSetupCard.vue';
import ChannelsInstagramAboutCard from '@/components/Skills/Channels/ChannelsInstagramAboutCard.vue';
import ChannelsFacebookAboutCard from '@/components/Skills/Channels/ChannelsFacebookAboutCard.vue';
import ChannelsWebChatAboutCard from '@/components/Skills/Channels/ChannelsWebChatAboutCard.vue';
import ChannelsWebChatStylesCard from '@/components/Skills/Channels/ChannelsWebChatStylesCard.vue';
import MainAppsMagentoAboutCard from '@/components/Skills/MainApps/MainAppsMagentoAboutCard.vue';
import MainAppsShopifyAboutCard from '@/components/Skills/MainApps/MainAppsShopifyAboutCard.vue';
import MainAppsPrestashopAboutCard from '@/components/Skills/MainApps/MainAppsPrestashopAboutCard.vue';
import communicationChannelsService from '@/services/account/communicationChannelsService';
import ConnectionDetails from '@/components/Skills/MainApps/MainAppsShopifyConnectionDetails.vue';
import MainAppsPrestashopConnectionDetails from '@/components/Skills/MainApps/MainAppsPrestashopConnectionDetails.vue';
import MainAppsMagentoConnectionDetails from '@/components/Skills/MainApps/MainAppsMagentoConnectionDetails.vue';
import AbandonCartTemplateConfig from '@/components/Skills/LixsaSkills/AbandonCart/LixsaSkillAbandonCartTemplateConfig.vue';
import ShippingUpdateNotificationsAboutCard from './LixsaSkills/ShippingUpdateNotifications/LixsaSkillShippingUpdateNotificationsAboutCard.vue';
import ThePaywallModal from '@/components/ThePaywallModal.vue';

// tour
import whatsappChannelTourSteps from '@/services/tours/sectionsTours/whatsappChannelTour';
import tourService from '@/services/tours/tourService';
import analyticsService from '@/services/analytics/analyticsService';

const SKILLS_WITH_PAYWALL = [{ name: 'Abandon Checkout', id: 'ab10e42f-fb65-48f9-bd0d-28b7489cccbj' }];

export default {
    name: 'SkillDetails',
    components: {
        ChannelsWhatsappAboutCard,
        ChannelsWhatsappHowToSetupCard,
        ChannelsInstagramAboutCard,
        ChannelsFacebookAboutCard,
        ChannelsWebChatAboutCard,
        ChannelsWebChatStylesCard,
        MainAppsShopifyAboutCard,
        MainAppsPrestashopAboutCard,
        SkillDetailsAboutCard,
        SkillDetailsInfoCard,
        ConnectionDetails,
        MainAppsPrestashopConnectionDetails,
        MainAppsMagentoConnectionDetails,
        AbandonCartTemplateConfig,
        ShippingUpdateNotificationsAboutCard,
        MainAppsMagentoAboutCard,
    },
    data() {
        return {
            skill: {},
            isEnable: false,
            isLoadingSkillInfo: true,
            communicationChannel: {},
            currentSkillId: null,
            tourInstance: null,
        };
    },

    computed: {
        ...mapGetters('membership', ['ifSubscribed']),
        ...mapGetters('tours', ['isCompleted']),
        isWhatsappSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cc';
        },
        isInstagramSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cd'; // Example UUID for Instagram skill
        },
        isFacebookSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284ce'; // Example UUID for Facebook skill
        },
        isWebChatSkill() {
            return this.skill.uuid === '36578113-0f50-4a38-ac06-3130f188281e'; // Example UUID for WebChat skill
        },
        isCommunicationChannelSkill() {
            return this.skill.type === 'CHANNEL';
        },
        communicationChannelType() {
            switch (this.skill.uuid) {
                case '8c0f230d-cca0-4335-a359-d3fd4ab284cc':
                    return 'WHATSAPP';
                case '8c0f230d-cca0-4335-a359-d3fd4ab284cd':
                    return 'INSTAGRAM';
                case '8c0f230d-cca0-4335-a359-d3fd4ab284ce':
                    return 'FACEBOOK';
                case '36578113-0f50-4a38-ac06-3130f188281e':
                    return 'WEBCHAT';
                default:
                    return null;
            }
        },
        isShopifySkill() {
            return this.skill.uuid === 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
        },
        isPrestashopSkill() {
            return this.skill.uuid === '124f8439-bca9-420a-8f20-c1b80e5f7512';
        },
        isMagentoSkill() {
            return this.skill.uuid === '742de54f-6ed3-4357-86dd-5be8e329c0f8';
        },
        isAbandonCartSkill() {
            return this.skill.uuid === 'ab10e42f-fb65-48f9-bd0d-28b7489cccbj';
        },
        isShippingUpdateNotificationsSkill() {
            return this.skill.uuid === 'd0b3e289-e8f3-4b79-b0a9-4c857c880ac6';
        },
        isSkillWithPaywall() {
            return SKILLS_WITH_PAYWALL.some((skill) => skill.id === this.skill.uuid);
        },
    },

    watch: {
        '$route.params.skillId': {
            immediate: true,
            async handler(newSkillId) {
                if (this.currentSkillId !== newSkillId) {
                    this.currentSkillId = newSkillId;
                    await this.loadSkillData();
                    this.startWhatsappChannelTour();
                }
            },
        },
    },

    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),
        ...mapActions('agentSettings', ['updateAgentSetting']),
        ...mapActions('modalStore', ['openModal']),
        goBack() {
            this.$router.push('/skills/discovery');
        },

        async loadSkillData() {
            if (!this.currentSkillId) return;

            this.isLoadingSkillInfo = true;
            this.skill = {};
            this.isEnable = false;
            this.communicationChannel = {};

            try {
                await this.fetchSkillInformation(this.currentSkillId);
                await this.checkIfTheSkillIsEnabledForTheAccount();

                if (this.isCommunicationChannelSkill) {
                    await this.updateAgentSetting();
                    await this.getCommunicationChannel(this.communicationChannelType);
                    this.subscribeToChannel();
                }
            } catch (error) {
                console.error('Error loading skill data:', error);
                this.showErrorToast('Error loading skill data');
            } finally {
                this.isLoadingSkillInfo = false;
            }
        },

        async fetchSkillInformation(skillId) {
            try {
                const response = await SkillsService.getSkill(skillId);
                this.skill = response.data;
            } catch (error) {
                console.error('Error fetching Skill details: ', error);
                throw error;
            }
        },

        async checkIfTheSkillIsEnabledForTheAccount() {
            try {
                const response = await AgentSettingsService.getAgentSettings();
                const skillsList = [...response.data.skills];
                this.isEnable = skillsList.some((skill) => skill.skill_id === this.skill.uuid);
            } catch (error) {
                console.error('Error checking account skills: ', error);
                throw error;
            }
        },

        async getCommunicationChannel(type) {
            try {
                const fetchCommunicationChannelsResponse =
                    await communicationChannelsService.fetchCommunicationChannels();

                const filteredCommunicationChannels = fetchCommunicationChannelsResponse.data.content.find(
                    (channel) => channel.type === type,
                );

                this.communicationChannel = filteredCommunicationChannels;
            } catch (error) {
                console.error('Error getting communication channel: ', error);
                throw error;
            }
        },

        async handleEnableSkill(event = null) {
            if (this.isSkillWithPaywall && !this.ifSubscribed) {
                this.$toasted.error('You need to be subscribed to use this skill', {
                    position: 'bottom-center',
                    icon: 'error',
                    duration: 3000,
                });
                this.openPaywallModal();
                return;
            }

            try {
                const reqData = {
                    skill_id: this.skill.uuid,
                    agent_id: this.$store.state.user.user.account_id,
                    account_id: this.$store.state.user.user.account_id,
                };

                // Only add config_schema_values if event is not null
                if (event !== null) {
                    reqData.config_schema_values = { ...event };
                }

                await AgentSettingsService.addSkillToAgentSettings(reqData);
                await this.updateAgentSetting();
                if (this.isWhatsappSkill) {
                    await this.getCommunicationChannel(this.communicationChannelType);
                    if (!this.isCompleted('whatsapp-channel-tour')) {
                        this.tourInstance.show('whatsapp-final-step');
                    }
                }

                this.showSuccessToast('Skill activated correctly');
                analyticsService.trackEvent('skill_activated', {
                    skill_id: this.skill.uuid,
                    skill_name: this.skill.name,
                    skill_internal_name: this.skill.internal_name,
                    skill_type: this.skill.type,
                });
            } catch (error) {
                console.error('Error activating Skill: ', error);
                this.showErrorToast('Error activating skill');
            }
        },

        handleUpdatedCommunicationChannel() {
            if (this.isInstagramSkill || this.isFacebookSkill) {
                setTimeout(async () => {
                    await this.getCommunicationChannel(this.communicationChannelType);
                    await this.updateAgentSetting();
                    window.location.reload();
                }, 3000);
            }
            if (this.isWebChatSkill) {
                setTimeout(async () => {
                    await this.getCommunicationChannel(this.communicationChannelType);
                    await this.updateAgentSetting();
                    window.location.reload();
                }, 100);
            }
        },

        handleDisableSkill() {
            Swal.fire({
                title: 'Are you sure?',
                text: `This action will deactivate the skill and all its features.`,
                showCancelButton: true,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Yes, disable it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.disableSkill();
                }
            });
        },

        disableSkill() {
            this.removeAgentSettingsSkills();
        },

        async removeAgentSettingsSkills() {
            try {
                const reqData = {
                    skill_id: this.skill.uuid,
                    agent_id: this.$store.state.user.user.account_id,
                    account_id: this.$store.state.user.user.account_id,
                };
                await AgentSettingsService.removeSkillFromAgentSettings(this.skill.uuid, reqData);
                await this.updateAgentSetting();
                this.isEnable = false;
                this.showSuccessToast('Skill deactivated correctly');
            } catch (error) {
                console.error('Error deactivating Skill: ', error);
                this.showErrorToast('Error deactivating Skill');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg, duration = 3000) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: duration,
            });
        },

        subscribeToChannel() {
            const topic = `/account/${this.$store.state.user.user.account_id}/communication-channel`;
            this.subscribe({
                topic,
                callback: this.handleChannelEvent,
            });
        },

        handleChannelEvent(message) {
            if (!message || !message.data) return;

            switch (message.name) {
                case 'CommunicationChannelCreated':
                    this.handleEnableSkill();
                    break;
                case 'CommunicationChannelUpdated':
                    this.handleUpdatedCommunicationChannel();
                    break;
                case 'CommunicationChannelInstallError':
                    this.showErrorToast('Error installing communication channel');
                    this.showErrorToast(message.error_message, 25000);
                    break;
            }
        },

        openPaywallModal() {
            this.openModal({
                component: ThePaywallModal,
            });
        },

        startWhatsappChannelTour() {
            this.$nextTick(() => {
                if (this.isWhatsappSkill && !this.isCompleted('whatsapp-channel-tour')) {
                    this.tourInstance = tourService.startTour('whatsapp-channel-tour', whatsappChannelTourSteps);

                    this.tourInstance.on('complete', () => {
                        const currentStep = this.tourInstance.getCurrentStep().id;
                        if (currentStep === 'whatsapp-final-step') {
                            this.$router.push({ name: 'Customer Support' });
                        } else if (currentStep === 'whatsapp-login-button-step') {
                            this.$router.push({ name: 'Playground' });
                        }
                    });
                }
            });
        },
    },

    beforeDestroy() {
        if (this.isWhatsappSkill) {
            const topic = `/account/${this.$store.state.user.user.account_id}/communication-channel/${this.$store.state.user.user.account_id}`;
            this.unsubscribe({
                topic,
                callback: this.handleChannelEvent,
            });
        }
    },
};
</script>

<template>
    <div class="md-layout skills-details">
        <div class="md-layout-item md-size-100">
            <div class="skills-details__back-button" @click="goBack">
                <md-icon class="skills-details__back-button-icon">keyboard_backspace</md-icon>
                <span class="md-body-1">Back to skills</span>
            </div>
            <div v-if="isLoadingSkillInfo">
                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-else>
                <skill-details-info-card
                    @skill-disabled="handleDisableSkill"
                    @skill-enabled="handleEnableSkill"
                    :skill="skill"
                    :is-enable="isEnable"
                />
                <channels-whatsapp-about-card
                    :communication-channel="communicationChannel"
                    v-if="isWhatsappSkill && isEnable"
                ></channels-whatsapp-about-card>
                <channels-instagram-about-card
                    :communication-channel="communicationChannel"
                    v-if="isInstagramSkill && isEnable"
                ></channels-instagram-about-card>
                <channels-facebook-about-card
                    :communication-channel="communicationChannel"
                    v-if="isFacebookSkill && isEnable"
                ></channels-facebook-about-card>
                <ChannelsWebChatAboutCard
                    :communication-channel="communicationChannel"
                    v-if="isWebChatSkill && isEnable"
                ></ChannelsWebChatAboutCard>
                <ChannelsWebChatStylesCard
                    :communication-channel="communicationChannel"
                    v-if="isWebChatSkill && isEnable"
                ></ChannelsWebChatStylesCard>
                <channels-whatsapp-how-to-setup-card
                    :communication-channel="communicationChannel"
                    v-if="isWhatsappSkill && !isEnable"
                ></channels-whatsapp-how-to-setup-card>
                <shipping-update-notifications-about-card
                    v-if="isShippingUpdateNotificationsSkill"
                ></shipping-update-notifications-about-card>
                <main-apps-shopify-about-card v-if="isShopifySkill && !isEnable"></main-apps-shopify-about-card>
                <main-apps-prestashop-about-card
                    v-if="isPrestashopSkill && !isEnable"
                ></main-apps-prestashop-about-card>
                <main-apps-magento-about-card v-if="isMagentoSkill && !isEnable"></main-apps-magento-about-card>
                <main-apps-prestashop-connection-details
                    v-if="isPrestashopSkill && isEnable"
                ></main-apps-prestashop-connection-details>
                <connection-details v-if="isShopifySkill && isEnable"></connection-details>
                <main-apps-magento-connection-details
                    v-if="isMagentoSkill && isEnable"
                ></main-apps-magento-connection-details>
                <abandon-cart-template-config
                    v-if="isAbandonCartSkill"
                    :is-skill-enabled="isEnable"
                ></abandon-cart-template-config>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/variables';

.skills-details {
    padding: 0 15px;

    &__back-button {
        color: $gray !important;
        margin-bottom: 15px;
        width: fit-content;

        &:hover {
            cursor: pointer;
            color: $gray-darker !important;

            i {
                color: $gray-darker !important;
            }
        }
    }

    &__back-button-icon {
        color: $gray !important;
        margin-right: 8px;
    }
}

.md-layout-item {
    @media (max-width: 959px) {
        padding: 0;
    }
}
</style>
