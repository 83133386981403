<script>
import { mapGetters } from 'vuex';
import webChatService from '@/services/webchat/webChatService';

export default {
    name: 'ChannelsWebChatStylesCard',
    props: ['communicationChannel'],
    data() {
        return {
            isLoading: false,
            isGeneratingTheme: false,
            websiteUrl: '',
            styleVariables: {
                '--lixsa-primary': '#4f46e5',
                '--lixsa-primary-gradient': 'linear-gradient(135deg, #4f46e5, #7c3aed)',
                '--lixsa-text': '#1f2937',
                '--lixsa-text-user': '#ffffff',
                '--lixsa-text-light': '#6b7280',
                '--lixsa-text-light-secondary': '#9ca3af',
                '--lixsa-bg': '#ffffff',
                '--lixsa-bg-secondary': '#f9fafb',
                '--lixsa-shadow-sm': '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03)',
                '--lixsa-shadow-md': '0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.04)',
                '--lixsa-shadow-lg': '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.05)',
                '--lixsa-radius-sm': '0.375rem',
                '--lixsa-radius-md': '0.5rem',
                '--lixsa-radius-lg': '1rem',
                '--lixsa-radius-full': '9999px',
                '--lixsa-transition': 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                '--lixsa-font-family':
                    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
            },
            gradientSettings: {
                '--lixsa-primary-gradient': {
                    angle: 135,
                    startColor: '#4f46e5',
                    endColor: '#7c3aed',
                },
            },
            variableNames: {
                '--lixsa-primary': 'Color of User Messages',
                '--lixsa-primary-gradient': 'Primary Gradient',
                '--lixsa-text': 'Color of AI Messages Text',
                '--lixsa-text-user': 'Text Color in User Messages',
                '--lixsa-text-light': 'Color of AI Messages Timestamp',
                '--lixsa-text-light-secondary': 'Color of User Messages Timestamp',
                '--lixsa-bg': 'Background Color',
                '--lixsa-bg-secondary': 'Color of AI Messages Background',
                '--lixsa-shadow-sm': 'Small Shadow',
                '--lixsa-shadow-md': 'Medium Shadow',
                '--lixsa-shadow-lg': 'Large Shadow',
                '--lixsa-radius-sm': 'Small Border Radius',
                '--lixsa-radius-md': 'Medium Border Radius',
                '--lixsa-radius-lg': 'Large Border Radius',
                '--lixsa-radius-full': 'Full Border Radius',
                '--lixsa-transition': 'Transition',
                '--lixsa-font-family': 'Font Family',
            },
            variableGroups: {
                colors: [
                    '--lixsa-primary',
                    '--lixsa-text',
                    '--lixsa-text-user',
                    '--lixsa-text-light',
                    '--lixsa-text-light-secondary',
                    '--lixsa-bg',
                    '--lixsa-bg-secondary',
                ],
                gradients: ['--lixsa-primary-gradient'],
                spacing: ['--lixsa-radius-sm', '--lixsa-radius-md', '--lixsa-radius-lg', '--lixsa-radius-full'],
                effects: ['--lixsa-shadow-sm', '--lixsa-shadow-md', '--lixsa-shadow-lg', '--lixsa-transition'],
                typography: ['--lixsa-font-family'],
            },
            originalStyles: {},
            shadowSettings: {
                '--lixsa-shadow-sm': {
                    x: 0,
                    y: 1,
                    blur: 3,
                    spread: 0,
                    color: 'rgba(0, 0, 0, 0.05)',
                    secondX: 0,
                    secondY: 1,
                    secondBlur: 2,
                    secondSpread: 0,
                    secondColor: 'rgba(0, 0, 0, 0.03)',
                },
                '--lixsa-shadow-md': {
                    x: 0,
                    y: 4,
                    blur: 6,
                    spread: -1,
                    color: 'rgba(0, 0, 0, 0.08)',
                    secondX: 0,
                    secondY: 2,
                    secondBlur: 4,
                    secondSpread: -1,
                    secondColor: 'rgba(0, 0, 0, 0.04)',
                },
                '--lixsa-shadow-lg': {
                    x: 0,
                    y: 10,
                    blur: 25,
                    spread: -5,
                    color: 'rgba(0, 0, 0, 0.1)',
                    secondX: 0,
                    secondY: 8,
                    secondBlur: 10,
                    secondSpread: -6,
                    secondColor: 'rgba(0, 0, 0, 0.05)',
                },
            },
            rotatingVariable: null,
            rotationCenter: null,
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getWebChatSkill() {
            return this.getSkillByInternalName('webchat');
        },

        defaultWebsiteUrl() {
            if (!this.getWebChatSkill?.config_values?.allowed_domains) {
                return '';
            }

            const domains = this.getWebChatSkill.config_values.allowed_domains
                .split(',')
                .filter((domain) => domain.trim() !== '');

            if (domains.length === 0) {
                return '';
            }

            // Use the first domain as default
            let domain = domains[0].trim();

            // Add protocol if missing
            if (!domain.startsWith('http://') && !domain.startsWith('https://')) {
                domain = 'https://' + domain;
            }

            return domain;
        },

        configuredStyles() {
            if (!this.getWebChatSkill?.config_values?.style_variables) {
                return this.styleVariables;
            }

            try {
                return JSON.parse(this.getWebChatSkill.config_values.style_variables);
            } catch (error) {
                console.error('Error parsing style variables:', error);
                return this.styleVariables;
            }
        },

        hasChanges() {
            return Object.keys(this.styleVariables).some(
                (key) => this.styleVariables[key] !== this.originalStyles[key],
            );
        },

        previewStyles() {
            return Object.entries(this.styleVariables)
                .map(([key, value]) => `${key}: ${value};`)
                .join(' ');
        },
    },
    created() {
        this.loadStyleVariables();
        this.websiteUrl = this.defaultWebsiteUrl;
    },
    methods: {
        loadStyleVariables() {
            const configStyles = this.configuredStyles;

            // Merge default values with configured values
            this.styleVariables = {
                ...this.styleVariables,
                ...configStyles,
            };

            // Parse gradients into components
            this.parseGradients();

            // Parse shadows into components
            this.parseShadows();

            // Store original styles for comparison
            this.originalStyles = JSON.parse(JSON.stringify(this.styleVariables));
        },

        parseGradients() {
            // Parse gradient strings into components
            Object.keys(this.styleVariables).forEach((key) => {
                if (this.isGradientVariable(key)) {
                    const value = this.styleVariables[key];
                    const match = value.match(/linear-gradient\((\d+)deg,\s*([^,]+),\s*([^)]+)\)/);

                    if (match) {
                        this.gradientSettings[key] = {
                            angle: parseInt(match[1], 10),
                            startColor: match[2].trim(),
                            endColor: match[3].trim(),
                        };
                    } else {
                        // Default values if parsing fails
                        this.gradientSettings[key] = {
                            angle: 135,
                            startColor: '#4f46e5',
                            endColor: '#7c3aed',
                        };
                    }
                }
            });
        },

        updateGradient(key) {
            // Update the gradient string based on the component values
            const settings = this.gradientSettings[key];
            this.styleVariables[key] =
                `linear-gradient(${settings.angle}deg, ${settings.startColor}, ${settings.endColor})`;
        },

        getFriendlyName(variable) {
            return this.variableNames[variable] || variable;
        },

        getRadiusPixelValue(value) {
            // Convert rem to pixels for the slider
            if (value.endsWith('rem')) {
                return parseFloat(value) * 16;
            } else if (value.endsWith('px')) {
                return parseFloat(value);
            }
            return parseInt(value, 10) || 0;
        },

        setRadiusValue(variable, pixelValue) {
            // Convert pixel value to rem for the CSS
            const remValue = (pixelValue / 16).toFixed(3);
            this.styleVariables[variable] = `${remValue}rem`;
        },

        parseRemValue(value) {
            if (value.endsWith('rem')) {
                return parseFloat(value);
            } else if (value.endsWith('px')) {
                return parseFloat(value) / 16;
            }
            return parseFloat(value) || 0;
        },

        formatRemValue(value) {
            return `${value}rem`;
        },

        resetToDefaults() {
            if (confirm('Are you sure you want to reset all styles to default?')) {
                this.styleVariables = {
                    '--lixsa-primary': '#4f46e5',
                    '--lixsa-primary-gradient': 'linear-gradient(135deg, #4f46e5, #7c3aed)',
                    '--lixsa-text': '#1f2937',
                    '--lixsa-text-user': '#ffffff',
                    '--lixsa-text-light': '#6b7280',
                    '--lixsa-text-light-secondary': '#9ca3af',
                    '--lixsa-bg': '#ffffff',
                    '--lixsa-bg-secondary': '#f9fafb',
                    '--lixsa-shadow-sm': '0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03)',
                    '--lixsa-shadow-md': '0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.04)',
                    '--lixsa-shadow-lg': '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.05)',
                    '--lixsa-radius-sm': '0.375rem',
                    '--lixsa-radius-md': '0.5rem',
                    '--lixsa-radius-lg': '1rem',
                    '--lixsa-radius-full': '9999px',
                    '--lixsa-transition': 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
                    '--lixsa-font-family':
                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                };

                // Re-parse gradients after reset
                this.parseGradients();
            }
        },

        getVariableType(variable) {
            if (variable.includes('gradient')) {
                return 'gradient';
            } else if (
                variable.includes('color') ||
                variable.includes('primary') ||
                variable.includes('text') ||
                variable.includes('bg')
            ) {
                return 'color';
            } else if (variable.includes('radius')) {
                return 'radius';
            } else if (variable.includes('shadow')) {
                return 'shadow';
            } else if (variable.includes('transition')) {
                return 'transition';
            } else if (variable.includes('font')) {
                return 'font';
            }
            return 'text';
        },

        isColorVariable(variable) {
            return this.getVariableType(variable) === 'color';
        },

        isGradientVariable(variable) {
            return this.getVariableType(variable) === 'gradient';
        },

        isRadiusVariable(variable) {
            return this.getVariableType(variable) === 'radius';
        },

        isShadowVariable(variable) {
            return this.getVariableType(variable) === 'shadow';
        },

        isTransitionVariable(variable) {
            return this.getVariableType(variable) === 'transition';
        },

        isFontVariable(variable) {
            return this.getVariableType(variable) === 'font';
        },

        parseShadows() {
            // Parse shadow strings into components
            Object.keys(this.styleVariables).forEach((key) => {
                if (this.isShadowVariable(key)) {
                    const value = this.styleVariables[key];
                    // Match pattern like: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03)
                    const parts = value.split(',').map((part) => part.trim());

                    if (parts.length >= 1) {
                        const firstShadow = parts[0];
                        const firstMatch = firstShadow.match(
                            /(-?\d+)px\s+(-?\d+)px\s+(-?\d+)px(?:\s+(-?\d+)px)?\s+(rgba?\([^)]+\))/,
                        );

                        if (firstMatch) {
                            this.shadowSettings[key] = {
                                ...this.shadowSettings[key],
                                x: parseInt(firstMatch[1], 10) || 0,
                                y: parseInt(firstMatch[2], 10) || 0,
                                blur: parseInt(firstMatch[3], 10) || 0,
                                spread: parseInt(firstMatch[4], 10) || 0,
                                color: firstMatch[5] || 'rgba(0, 0, 0, 0.1)',
                            };
                        }

                        if (parts.length > 1) {
                            const secondShadow = parts[1];
                            const secondMatch = secondShadow.match(
                                /(-?\d+)px\s+(-?\d+)px\s+(-?\d+)px(?:\s+(-?\d+)px)?\s+(rgba?\([^)]+\))/,
                            );

                            if (secondMatch) {
                                this.shadowSettings[key] = {
                                    ...this.shadowSettings[key],
                                    secondX: parseInt(secondMatch[1], 10) || 0,
                                    secondY: parseInt(secondMatch[2], 10) || 0,
                                    secondBlur: parseInt(secondMatch[3], 10) || 0,
                                    secondSpread: parseInt(secondMatch[4], 10) || 0,
                                    secondColor: secondMatch[5] || 'rgba(0, 0, 0, 0.05)',
                                };
                            }
                        }
                    } else {
                        // Use default values if parsing fails
                        console.log('Failed to parse shadow:', value);
                    }
                }
            });
        },

        startAngleRotation(event, variable) {
            event.preventDefault();

            // Store which variable we're rotating
            this.rotatingVariable = variable;

            // Add event listeners for mouse/touch movement and release
            if (event.type === 'mousedown') {
                document.addEventListener('mousemove', this.handleAngleRotation);
                document.addEventListener('mouseup', this.stopAngleRotation);
            } else if (event.type === 'touchstart') {
                document.addEventListener('touchmove', this.handleAngleRotation, { passive: false });
                document.addEventListener('touchend', this.stopAngleRotation);
            }

            // Get the center of the rotation element
            const rect = event.target.getBoundingClientRect();
            this.rotationCenter = {
                x: rect.left + rect.width / 2,
                y: rect.top + rect.height / 2,
            };
        },

        handleAngleRotation(event) {
            if (!this.rotatingVariable || !this.rotationCenter) return;

            // Prevent default to avoid scrolling on touch devices
            event.preventDefault();

            // Get current pointer position
            const clientX = event.clientX || event.touches[0].clientX;
            const clientY = event.clientY || event.touches[0].clientY;

            // Calculate angle between center and pointer
            const deltaX = clientX - this.rotationCenter.x;
            const deltaY = clientY - this.rotationCenter.y;
            let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

            // Convert to 0-360 degrees, with 0 at the top
            angle = (angle + 90) % 360;
            if (angle < 0) angle += 360;

            // Update the angle in the gradient settings
            this.gradientSettings[this.rotatingVariable].angle = Math.round(angle);
            // Update the gradient
            this.updateGradient(this.rotatingVariable);
        },

        stopAngleRotation() {
            // Remove event listeners
            document.removeEventListener('mousemove', this.handleAngleRotation);
            document.removeEventListener('mouseup', this.stopAngleRotation);
            document.removeEventListener('touchmove', this.handleAngleRotation);
            document.removeEventListener('touchend', this.stopAngleRotation);

            // Clear rotation state
            this.rotatingVariable = null;
            this.rotationCenter = null;
        },

        updateShadow(key) {
            // Construct the shadow string from components
            const settings = this.shadowSettings[key];
            let shadowValue = `${settings.x}px ${settings.y}px ${settings.blur}px`;

            // Add spread if it exists
            if (settings.spread !== undefined) {
                shadowValue += ` ${settings.spread}px`;
            }

            // Add color
            shadowValue += ` ${settings.color}`;

            // Add second shadow if needed
            if (settings.secondX !== undefined) {
                shadowValue += `, ${settings.secondX}px ${settings.secondY}px ${settings.secondBlur}px`;

                if (settings.secondSpread !== undefined) {
                    shadowValue += ` ${settings.secondSpread}px`;
                }

                shadowValue += ` ${settings.secondColor}`;
            }

            this.styleVariables[key] = shadowValue;
        },

        updateShadowColor(key, colorProperty, hexColor) {
            // Extract alpha value from existing rgba color
            const currentColor = this.shadowSettings[key][colorProperty];
            let alpha = 0.1; // default alpha

            const match = currentColor.match(/rgba?\([^,]+,[^,]+,[^,]+,([^)]+)\)/);
            if (match) {
                alpha = parseFloat(match[1]);
            }

            // Convert hex to rgb
            const r = parseInt(hexColor.slice(1, 3), 16);
            const g = parseInt(hexColor.slice(3, 5), 16);
            const b = parseInt(hexColor.slice(5, 7), 16);

            // Update the color with new rgb values but keep the alpha
            this.shadowSettings[key][colorProperty] = `rgba(${r}, ${g}, ${b}, ${alpha})`;

            // Update the shadow
            this.updateShadow(key);
        },

        async updateStyleVariables() {
            this.isLoading = true;

            try {
                // Create a method in webChatService to update style variables
                // For now, we'll assume it works like updateAllowedDomains
                await this.updateWebChatStyles();

                this.showSuccessToast('WebChat styles updated successfully');

                // Update the store
                this.$store.dispatch('agentSettings/getAgentSettings');

                // Store original styles for comparison
                this.originalStyles = JSON.parse(JSON.stringify(this.styleVariables));
            } catch (error) {
                console.error('Error updating WebChat styles:', error);
                this.showErrorToast('Error updating WebChat styles');
            } finally {
                this.isLoading = false;
            }
        },

        async updateWebChatStyles() {
            try {
                await webChatService.updateStyleVariables(this.styleVariables);
            } catch (error) {
                console.error('Error updating WebChat styles:', error);
                throw error;
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        async generateThemeFromUrl() {
            if (!this.websiteUrl) {
                this.showErrorToast('Please enter a valid URL');
                return;
            }

            this.isGeneratingTheme = true;

            try {
                const newStyles = await webChatService.generateThemeFromUrl(this.websiteUrl);

                // Update the style variables with the generated ones
                Object.keys(newStyles).forEach((key) => {
                    if (this.styleVariables.hasOwnProperty(key)) {
                        this.styleVariables[key] = newStyles[key];
                    }
                });

                // Re-parse gradients
                this.parseGradients();

                this.showSuccessToast('Theme generated successfully!');
            } catch (error) {
                console.error('Error generating theme:', error);
                this.showErrorToast('Failed to generate theme from URL');
            } finally {
                this.isGeneratingTheme = false;
            }
        },
    },
};
</script>

<template>
    <div>
        <md-card>
            <md-card-content class="skill-about-card">
                <div class="md-title">
                    <strong>WebChat Styles</strong>
                </div>

                <div class="md-body-1">
                    <div>
                        <div class="md-subheading">
                            <strong>Appearance</strong>
                        </div>
                        <p>Customize the look and feel of your WebChat widget</p>

                        <div class="theme-generator">
                            <h5 class="theme-generator-title">Generate Theme from Your Website</h5>
                            <div class="theme-generator-form">
                                <md-field class="website-url-field">
                                    <label>Website URL</label>
                                    <md-input v-model="websiteUrl" placeholder="https://example.com"></md-input>
                                </md-field>
                                <md-button
                                    @click="generateThemeFromUrl"
                                    class="md-primary md-raised generate-btn"
                                    :disabled="isGeneratingTheme || !websiteUrl"
                                    :class="{ 'is-generating': isGeneratingTheme }"
                                >
                                    <div class="generate-btn-content">
                                        <md-progress-spinner
                                            v-if="isGeneratingTheme"
                                            :md-diameter="20"
                                            :md-stroke="2"
                                            md-mode="indeterminate"
                                            class="generate-spinner"
                                        ></md-progress-spinner>
                                        <span>{{ isGeneratingTheme ? 'Generating...' : 'Generate Theme' }}</span>
                                    </div>
                                </md-button>
                            </div>
                            <p class="theme-generator-help">
                                This will analyze your website's colors and generate a matching theme for your WebChat.
                            </p>
                        </div>

                        <div
                            class="style-preview-editing"
                            :style="previewStyles"
                            :class="{ 'is-generating': isGeneratingTheme }"
                        >
                            <div class="preview-loader" v-if="isGeneratingTheme">
                                <md-progress-spinner
                                    :md-diameter="40"
                                    :md-stroke="3"
                                    md-mode="indeterminate"
                                ></md-progress-spinner>
                                <span>Analyzing website colors...</span>
                            </div>
                            <div
                                class="preview-header"
                                :style="`background: var(--lixsa-primary-gradient); color: white;`"
                            >
                                Live Preview
                            </div>
                            <div class="preview-body" :style="`background: var(--lixsa-bg); color: var(--lixsa-text);`">
                                <div
                                    class="preview-message bot"
                                    :style="`background: var(--lixsa-bg-secondary); border-radius: var(--lixsa-radius-md); box-shadow: var(--lixsa-shadow-sm);`"
                                >
                                    <div class="message-content">Hello! How can I help you today?</div>
                                    <div class="message-time" :style="`color: var(--lixsa-text-light);`">10:30 AM</div>
                                </div>
                                <div
                                    class="preview-message user"
                                    :style="`background: var(--lixsa-primary); color: var(--lixsa-text-user); border-radius: var(--lixsa-radius-md);`"
                                >
                                    <div class="message-content">I have a question about your services.</div>
                                    <div class="message-time" :style="`color: var(--lixsa-text-light-secondary);`">
                                        10:32 AM
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="style-groups">
                            <!-- Colors -->
                            <div class="style-group">
                                <h4 class="style-group-title">Colors</h4>
                                <div class="style-variables grid-layout">
                                    <div
                                        v-for="variable in variableGroups.colors"
                                        :key="variable"
                                        class="variable-item"
                                    >
                                        <div class="variable-label">{{ getFriendlyName(variable) }}</div>
                                        <div class="variable-input color-input">
                                            <input
                                                type="color"
                                                v-model="styleVariables[variable]"
                                                class="color-picker"
                                            />
                                            <md-field>
                                                <md-input v-model="styleVariables[variable]"></md-input>
                                            </md-field>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Gradients -->
                            <div class="style-group">
                                <h4 class="style-group-title">Gradients</h4>
                                <div class="style-variables">
                                    <div
                                        v-for="variable in variableGroups.gradients"
                                        :key="variable"
                                        class="variable-item"
                                    >
                                        <div class="variable-label">{{ getFriendlyName(variable) }}</div>
                                        <div
                                            class="gradient-preview"
                                            :style="`background: ${styleVariables[variable]};`"
                                        ></div>
                                        <div class="gradient-controls">
                                            <div class="gradient-control">
                                                <label>Start Color</label>
                                                <div class="color-input">
                                                    <input
                                                        type="color"
                                                        v-model="gradientSettings[variable].startColor"
                                                        @change="updateGradient(variable)"
                                                        class="color-picker"
                                                    />
                                                    <md-field>
                                                        <md-input
                                                            v-model="gradientSettings[variable].startColor"
                                                            @change="updateGradient(variable)"
                                                        ></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <div class="gradient-control">
                                                <label>End Color</label>
                                                <div class="color-input">
                                                    <input
                                                        type="color"
                                                        v-model="gradientSettings[variable].endColor"
                                                        @change="updateGradient(variable)"
                                                        class="color-picker"
                                                    />
                                                    <md-field>
                                                        <md-input
                                                            v-model="gradientSettings[variable].endColor"
                                                            @change="updateGradient(variable)"
                                                        ></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <div class="gradient-control">
                                                <label>Gradient Direction</label>
                                                <div class="angle-selector">
                                                    <div
                                                        class="angle-preview"
                                                        :style="`background: ${styleVariables[variable]};`"
                                                        @mousedown="startAngleRotation($event, variable)"
                                                        @touchstart="startAngleRotation($event, variable)"
                                                    >
                                                        <div
                                                            class="angle-indicator"
                                                            :style="`transform: rotate(${gradientSettings[variable].angle}deg);`"
                                                        >
                                                            <div class="angle-arrow"></div>
                                                        </div>
                                                    </div>
                                                    <div class="angle-info">
                                                        <div class="angle-value">
                                                            {{ gradientSettings[variable].angle }}°
                                                        </div>
                                                        <div class="angle-hint">Drag to rotate or use slider</div>
                                                    </div>
                                                </div>
                                                <md-slider
                                                    v-model="gradientSettings[variable].angle"
                                                    @change="updateGradient(variable)"
                                                    :min="0"
                                                    :max="360"
                                                    :step="1"
                                                ></md-slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="action-buttons">
                            <md-button @click="resetToDefaults" class="md-accent reset-btn">
                                <md-icon>restore</md-icon>
                                Reset to Default
                            </md-button>
                            <div class="spacer"></div>
                            <md-button
                                @click="updateStyleVariables"
                                class="md-primary"
                                :disabled="isLoading || !hasChanges"
                            >
                                <div class="save-btn-content">
                                    <md-progress-spinner
                                        v-if="isLoading"
                                        :md-diameter="16"
                                        :md-stroke="2"
                                        md-mode="indeterminate"
                                    ></md-progress-spinner>
                                    <span>{{ isLoading ? 'Saving...' : 'Save Changes' }}</span>
                                </div>
                            </md-button>
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.skill-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.style-groups {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
}

.style-group {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
}

.style-group-title {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 18px;
    color: #333;
}

.grid-layout {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
}

.variable-item {
    margin-bottom: 16px;
}

.variable-label {
    font-size: 14px;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.color-input {
    display: flex;
    align-items: center;
    gap: 8px;
}

.color-picker {
    width: 40px;
    height: 40px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    padding: 0;
    cursor: pointer;
}

.variable-input .md-field {
    margin: 0;
    flex: 1;
}

.gradient-preview {
    height: 40px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.gradient-controls {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.gradient-control {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.gradient-control label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
}

.style-preview {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: hidden;
    margin: 24px 0;
    max-width: 360px;
}

.style-preview-editing {
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto 24px;
    max-width: 360px;
    box-shadow:
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    transition: all 0.5s ease;
}

.style-preview-editing.is-generating {
    opacity: 0.7;
    transform: scale(0.98);
}

.preview-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
}

.preview-loader span {
    color: white;
    font-weight: 500;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.preview-header {
    padding: 12px 16px;
    font-weight: 600;
}

.preview-body {
    padding: 16px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.preview-message {
    padding: 10px 14px;
    max-width: 80%;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
}

.message-content {
    margin-bottom: 4px;
}

.message-time {
    font-size: 0.75rem;
    text-align: right;
    opacity: 0.8;
}

.preview-message.bot {
    align-self: flex-start;
}

.preview-message.user {
    align-self: flex-end;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 24px;
}

.reset-btn {
    margin-right: auto;
}

.spacer {
    flex: 1;
}

.shadow-item {
    margin-bottom: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 16px;
}

.shadow-preview-container {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
}

.shadow-preview {
    height: 80px;
    width: 240px;
    border-radius: 8px;
    margin-bottom: 16px;
    background-color: white;
    position: relative;
    transition: all 0.3s ease;
}

.shadow-controls h5 {
    font-size: 15px;
    margin-top: 24px;
    margin-bottom: 12px;
    color: #333;
    display: flex;
    align-items: center;
}

.shadow-controls h5::before,
.shadow-controls h5::after {
    content: '';
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    flex: 1;
}

.shadow-controls h5::before {
    margin-right: 12px;
}

.shadow-controls h5::after {
    margin-left: 12px;
}

.shadow-control-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 16px;
    border-radius: 8px;
}

.shadow-control {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.shadow-control label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: space-between;
}

.shadow-control label span {
    font-weight: 500;
}

.shadow-color-input {
    display: flex;
    align-items: center;
    gap: 8px;
}

.opacity-field {
    flex: 1;
}

.angle-selector {
    display: flex;
    margin-bottom: 12px;
    gap: 16px;
    align-items: center;
}

.angle-preview {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: relative;
    border: 2px solid #e5e7eb;
    overflow: hidden;
    flex-shrink: 0;
    cursor: grab;
}

.angle-preview:active {
    cursor: grabbing;
}

.angle-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.angle-arrow {
    position: relative;
    width: 2px;
    height: 26px;
    background-color: rgba(255, 255, 255, 0.7);
}

.angle-arrow:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 6px solid rgba(255, 255, 255, 0.7);
}

.angle-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.angle-value {
    font-size: 1.1em;
    font-weight: 500;
    color: #4f46e5;
}

.angle-hint {
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.6);
}

.theme-generator {
    margin-bottom: 24px;
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #e5e7eb;
}

.theme-generator-title {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 16px;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
}

.theme-generator-title::before {
    content: 'palette';
    font-family: 'Material Icons';
    font-size: 20px;
    color: #4f46e5;
}

.theme-generator-form {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;
}

.website-url-field {
    flex: 1;
    margin-bottom: 0;
}

.generate-btn {
    margin-top: 0;
    height: 40px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.generate-btn-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.generate-btn.is-generating {
    background-color: #6366f1;
    min-width: 160px;
}

.generate-spinner {
    margin-right: 4px;
}

.theme-generator-help {
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0;
}

.save-btn-content {
    display: flex;
    align-items: center;
    gap: 8px;
}
</style>
