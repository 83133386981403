import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = (method, endpoint, data = null) => {
    return store.dispatch('user/apiCall', async (token) => {
        const config = {
            method,
            url: `${process.env.VUE_APP_API_URL}/${endpoint}`,
            headers: getAuthHeader(token),
        };

        if (data) {
            config.data = data;
        }

        return axios(config);
    });
};

const webChatService = {
    async setupWebChat(allowedDomains) {
        try {
            const requestData = {
                uuid: uuidv4(),
                account_id: store.state.user.user.account_id,
                channel_type: 'WEBCHAT',
                skill_id: '36578113-0f50-4a38-ac06-3130f188281e',
                config_values: {
                    allowed_domains: allowedDomains.join(','),
                },
            };

            return apiRequest(
                'post',
                `accounts/${store.state.user.user.account_id}/communication-channels`,
                requestData,
            );
        } catch (error) {
            console.error('Error setting up WebChat:', error);
            throw error;
        }
    },

    async updateAllowedDomains(allowedDomains) {
        try {
            const agentSettings = store.state.agentSettings.agentSettings;
            const webchatSkill = agentSettings.skills.find(
                (skill) => skill.skill_id === '36578113-0f50-4a38-ac06-3130f188281e',
            );

            webchatSkill.config_values.allowed_domains = allowedDomains.join(',');

            const updateData = {
                skillId: webchatSkill.skill_id,
                agentId: store.state.user.user.account_id,
                enabled: true,
                accountId: store.state.user.user.account_id,
                configValues: webchatSkill.config_values,
            };

            return apiRequest(
                'post',
                `accounts/${store.state.user.user.account_id}/agent-settings/${store.state.user.user.account_id}/skills/36578113-0f50-4a38-ac06-3130f188281e`,
                updateData,
            );
        } catch (error) {
            console.error('Error updating WebChat allowed domains:', error);
            throw error;
        }
    },

    async updateStyleVariables(styleVariables) {
        try {
            const agentSettings = store.state.agentSettings.agentSettings;
            // Find the webchat skill
            const webchatSkill = agentSettings.skills.find(
                (skill) => skill.skill_id === '36578113-0f50-4a38-ac06-3130f188281e',
            );

            // Update the style variables
            webchatSkill.config_values.style_variables = JSON.stringify(styleVariables);

            const updateData = {
                skillId: webchatSkill.skill_id,
                agentId: store.state.user.user.account_id,
                enabled: true,
                accountId: store.state.user.user.account_id,
                configValues: webchatSkill.config_values,
            };

            return apiRequest(
                'post',
                `accounts/${store.state.user.user.account_id}/agent-settings/${store.state.user.user.account_id}/skills/36578113-0f50-4a38-ac06-3130f188281e`,
                updateData,
            );
        } catch (error) {
            console.error('Error updating WebChat style variables:', error);
            throw error;
        }
    },

    async generateThemeFromUrl(url) {
        try {
            const response = await axios.get(
                `https://task.lixsa.ai/webhook/webchat/generate-theme?url=${encodeURIComponent(url)}`,
            );
            return response.data.output;
        } catch (error) {
            console.error('Error generating theme from URL:', error);
            throw error;
        }
    },
};

export default webChatService;
