<script>
import { mapGetters } from 'vuex';
import webChatService from '@/services/webchat/webChatService';
import CodeSnippet from '@/components/codeSnippet.vue';

export default {
    name: 'ChannelsWebChatAboutCard',
    props: ['communicationChannel'],
    components: { CodeSnippet },
    data() {
        return {
            isLoading: false,
            isEditing: false,
            allowedDomains: [],
            originalDomains: [],
            webChatParams: {
                language: 'en',
                welcomeMessage: 'Hello, how can I help you?',
            },
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getWebChatSkill() {
            return this.getSkillByInternalName('webchat');
        },

        configuredDomains() {
            if (!this.getWebChatSkill?.config_values?.allowed_domains) {
                return [];
            }

            return this.getWebChatSkill.config_values.allowed_domains
                .split(',')
                .filter((domain) => domain.trim() !== '');
        },

        hasValidDomains() {
            return this.allowedDomains.some((domain) => domain.trim() !== '');
        },

        hasChanges() {
            // Check if arrays have different length
            if (this.allowedDomains.length !== this.originalDomains.length) {
                return true;
            }

            // Check if any domain is different
            for (let i = 0; i < this.allowedDomains.length; i++) {
                if (this.allowedDomains[i] !== this.originalDomains[i]) {
                    return true;
                }
            }

            return false;
        },

        formattedCodeSnippet() {
            return (
                '<script>\n' +
                '!(function(lix,s,a){\n' +
                '  ((s=lix.createElement("script")).src="https://storage.googleapis.com/lixsa-statics/webchat/lixsa-chat.umd.cjs"),\n' +
                '  s.setAttribute("data-language", "' +
                this.webChatParams.language +
                '"),\n' +
                '  s.setAttribute("data-welcome-message", "' +
                this.webChatParams.welcomeMessage +
                '"),\n' +
                '  s.setAttribute("data-lixsa-id", "b87a3c29-aa5a-4831-ac4b-f27a2b06136a"),\n' +
                '  (s.async=true),\n' +
                '  (a=lix.getElementsByTagName("script")[0]).parentNode.insertBefore(s,a);\n' +
                '})(document);\n' +
                '<\/script>'
            );
        },
    },
    created() {
        this.loadAllowedDomains();
    },
    methods: {
        loadAllowedDomains() {
            this.allowedDomains = [...this.configuredDomains];
            if (this.allowedDomains.length === 0) {
                this.allowedDomains = [''];
            }
            // Store original domains for comparison
            this.originalDomains = [...this.allowedDomains];
        },

        startEditing() {
            this.isEditing = true;
            this.loadAllowedDomains();
        },

        cancelEditing() {
            this.isEditing = false;
            this.loadAllowedDomains();
        },

        addDomain() {
            this.allowedDomains.push('');
        },

        removeDomain(index) {
            if (this.allowedDomains.length > 1) {
                this.allowedDomains.splice(index, 1);
            } else {
                this.allowedDomains = [''];
            }
        },

        validateDomains() {
            // Filter out empty domains
            console.log(this.allowedDomains);
            return this.allowedDomains.filter((domain) => domain.trim() !== '');
        },

        async updateAllowedDomains() {
            this.isLoading = true;

            try {
                const validDomains = this.validateDomains();

                if (validDomains.length === 0) {
                    this.showErrorToast('Please enter at least one domain');
                    this.isLoading = false;
                    return;
                }

                await webChatService.updateAllowedDomains(validDomains);

                this.showSuccessToast('WebChat allowed domains updated successfully');
                this.isEditing = false;

                // Update the store (in a real implementation, you might want to refresh the data from the API)
                this.$store.dispatch('agentSettings/getAgentSettings');
            } catch (error) {
                console.error('Error updating WebChat allowed domains:', error);
                this.showErrorToast('Error updating WebChat allowed domains');
            } finally {
                this.isLoading = false;
            }
        },

        copyIntegrationCode() {
            const code = this.formattedCodeSnippet;

            navigator.clipboard
                .writeText(code)
                .then(() => {
                    this.showSuccessToast('Integration code copied to clipboard');
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                    this.showErrorToast('Failed to copy code');
                });
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<template>
    <div>
        <md-card>
            <md-card-content class="skill-about-card">
                <div class="md-title">
                    <strong>WebChat Configuration</strong>
                </div>

                <div class="md-body-1">
                    <div v-if="!isEditing">
                        <div class="md-subheading section-heading">
                            <strong>Allowed Domains</strong>
                        </div>
                        <div class="domains-container">
                            <div class="domains-list" v-if="configuredDomains.length">
                                <md-chip v-for="(domain, index) in configuredDomains" :key="index" class="domain-chip">
                                    {{ domain }}
                                </md-chip>
                            </div>
                            <p v-else class="no-domains">No domains configured</p>
                        </div>

                        <md-button @click="startEditing" class="md-primary edit-domains-btn">
                            <md-icon>edit</md-icon>
                            Edit Domains
                        </md-button>
                        <div class="info-note">
                            <md-icon>info</md-icon>
                            <span>Your WebChat will only work on the domains you have configured above.</span>
                        </div>
                    </div>

                    <div v-else class="edit-form">
                        <div class="md-subheading section-heading">
                            <strong>Edit Allowed Domains</strong>
                        </div>
                        <p>Enter domains that are allowed to integrate with your WebChat</p>

                        <div class="domain-list">
                            <div v-for="(domain, index) in allowedDomains" :key="index" class="domain-item">
                                <md-field>
                                    <label>Domain</label>
                                    <md-input v-model="allowedDomains[index]" placeholder="example.com"></md-input>
                                </md-field>
                                <md-button class="md-icon-button md-dense" @click="removeDomain(index)">
                                    <md-icon>close</md-icon>
                                </md-button>
                            </div>
                        </div>

                        <md-button class="md-primary md-raised add-domain-btn" @click="addDomain">
                            <md-icon>add</md-icon>
                            Add Domain
                        </md-button>

                        <div class="domain-info">
                            <md-icon>info</md-icon>
                            <div class="info-content">
                                <span>
                                    Enter domains without protocol (http://, https://). Use * as wildcard (e.g.,
                                    *.example.com).
                                </span>
                            </div>
                        </div>

                        <div class="action-buttons">
                            <md-button @click="cancelEditing" class="md-accent cancel-button"> Cancel </md-button>
                            <md-button
                                @click="updateAllowedDomains"
                                class="md-primary save-button"
                                :disabled="isLoading || !hasValidDomains || !hasChanges"
                            >
                                <md-progress-spinner
                                    v-if="isLoading"
                                    :md-diameter="16"
                                    :md-stroke="2"
                                    md-mode="indeterminate"
                                ></md-progress-spinner>
                                <span v-else>Save Changes</span>
                            </md-button>
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-card>
            <md-card-content class="skill-about-card">
                <div class="md-title">
                    <strong>Integration Code</strong>
                </div>

                <div class="md-body-1">
                    <p>Add this code to your website to enable WebChat:</p>

                    <div class="integration-params">
                        <md-field>
                            <label>Language</label>
                            <md-select v-model="webChatParams.language">
                                <md-option value="en">English</md-option>
                                <md-option value="es">Spanish</md-option>
                            </md-select>
                        </md-field>
                        <md-field>
                            <label>Welcome Message</label>
                            <md-input
                                v-model="webChatParams.welcomeMessage"
                                placeholder="Hello, how can I help you?"
                            ></md-input>
                        </md-field>
                    </div>

                    <code-snippet :code="formattedCodeSnippet" language="language-html" />

                    <div style="margin-top: 16px">
                        <md-button class="md-primary" @click="copyIntegrationCode">
                            <md-icon>content_copy</md-icon>
                            Copy Code
                        </md-button>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.skill-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.section-heading {
    margin-bottom: 16px;
}

.domains-container {
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    min-height: 48px;
}

.domains-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.domain-chip {
    background-color: #f5f5f5;
}

.no-domains {
    color: rgba(0, 0, 0, 0.54);
    font-style: italic;
}

.edit-domains-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
}

.info-note {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
}

.info-note .md-icon {
    font-size: 18px;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.54);
}

.domain-list {
    margin-bottom: 16px;
}

.domain-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 0 8px;
}

.domain-item .md-field {
    flex: 1;
    margin-right: 8px;
}

.add-domain-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 16px;
}

.domain-info {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 20px;
}

.domain-info .md-icon {
    font-size: 18px;
    min-width: 18px;
    margin-right: 8px;
    color: rgba(0, 0, 0, 0.54);
}

.info-content {
    flex: 1;
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 16px;
    border-top: 1px solid #e0e0e0;
}

.cancel-button,
.save-button {
    min-width: 100px;
}

.integration-params {
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}
</style>
